import functions from "./functions.js";
import {InputGuests} from "./components/input-guests";

const contactRequest = {
    $form : null,
    form : null,
    init() {
        this.$form = $('form[id^=contactRequest-]');
        this.form = this.$form[0];

        if (! this.form) {
            return;
        }

        // global datepicker init with automatic "dateTo" set if provided
        let displayDate = new Date('2019-06-01');
        let now = functions.cleanDate(new Date);
        if ( now > displayDate ) {
            displayDate = now;
        }

        this.$form.find('.datepicker-range').each((i, elem) => {
            const $datepicker = $(elem);
            const minDate = $datepicker.attr('data-min');
            const maxDate = $datepicker.attr('data-max');
            const arrival = $datepicker.attr('data-arrival') ? $datepicker.attr('data-arrival') : [];
            const defaultDate = $datepicker.attr('data-defaultDate') ? $datepicker.attr('data-defaultDate') : [];
            const duration = Number($datepicker.attr('data-duration'));
            $datepicker.flatpickr({
                mode: "range",
                showMonths: functions.isMobile() ? 1 : 2,
                altInput: true,
                wrap: true,
                altFormat: "j.m.Y",
                dateFormat: "Y-m-d",
                defaultDate: defaultDate,
                minDate: minDate && minDate >= now ? minDate : now,
                maxDate: maxDate ? maxDate : null,
                disableMobile: true,
                prevArrow: '<i class="la la-arrow-circle-left la-fw la-2x pr-2"></i>',
                nextArrow: '<i class="la la-arrow-circle-right la-fw la-2x pl-2"></i>',
                locale: {
                    rangeSeparator: ' - '
                },
                onDayCreate(dObj, dStr, fp, dayElem){

                    let to = new Date(maxDate);
                    let weekday =  new Date(dayElem.dateObj).getDay();
                    weekday = '' + (weekday ? weekday : 7);
                    to.setHours(0, 0, 0, 0);

                    if ( dayElem.dateObj > to || (arrival.length && $.inArray( weekday , arrival ) == -1) ) {
                        dayElem.classList.add('unselectable');
                    }
                }
            });
            // to style clear toggler
            $datepicker.on('change', e => requestAnimationFrame(() => $(e.target).parent().toggleClass('datepicker-value', !!e.target.value)));
        });

        this.$form.find('.input-guests').each((i, elem) => {
            elem._inputGuests = new InputGuests(elem);
        });

        this.$form.on('submit', e => {
            e.preventDefault();

            const $target = $(e.currentTarget);
            const index = $target.attr('data-index');

            let params = {};
            $.each($target.serializeArray(), function (i, field) {
                params[field.name] = field.value;
            });

            params['language'] = functions.getLang();
            //params['contactRequest'] = true;
            params['template'] = 'contactRequest';

            const $dates = $target.find('[name="dates"]');
            //const $dateTo = this.$form.find('[name="dateTo"]');
            const $guests = $target.find('[name="guests"]');
            $dates.parents('.datepicker-range').toggleClass('focus', ! params['dates'] && ! $dates.val());
            //$dateTo.parents('.datepicker').toggleClass('focus', ! params['dateTo'] && ! $dateTo.val());
            $guests.parents('#dropdown-guests').find('.input-guests').toggleClass('focus', ! params['guests'] && ! $guests.val());
    
            $dates.on('change', e => {
                $(e.currentTarget).parents('.datepicker-range').removeClass('focus');
            });
            /*$dateTo.on('change', e => {
                $(e.currentTarget).parents('.datepicker').removeClass('focus');
            });*/
            $guests.on('change', e => {
                $(e.currentTarget).parents('#dropdown-guests').find('.input-guests').removeClass('focus');
            });

            //$output.html('').addClass('d-none');
            if((params['dates'] && params['guests'])) {

                $('.contact-btn').addClass('d-none');
                $('.contact-spinner').removeClass('d-none');
                $('.contact-spinner').addClass('d-flex');
                
                grecaptcha.ready(function () {
                    grecaptcha.execute('6LfqrMwkAAAAAFr6Sia857dEa-aTaBkjn4tSUCwN', { action: 'contact' }).then(function (token) {
                        params['gRecaptchaToken'] = token;
                        params = functions.getDataForUrl(params);
                        //console.log(params);
                        $.post('/services/contact/', params).then(response => {
                            //console.log(response);
                            response = JSON.parse(response);

                            //console.log(response.data);
        
                            $('#contact-request-modal-' + index).modal('show');
                            $(".response-output").html(response.data);
        
                            $('.contact-btn').removeClass('d-none');
                            $('.contact-spinner').removeClass('d-flex');
                            $('.contact-spinner').addClass('d-none');
                            $('#contactRequestModal-' + index).modal('hide');
        
                            setTimeout(() => { 
                                $('#contact-request-modal-' + index).modal('hide');
                                // details contact modal 
                            }, 4000);
        
                            if (response.status == true) {
                                $target[0].reset();
                            }
                        });
                    });
                });
            }

        });
    }
};

module.exports = {
    contactRequest: contactRequest
};